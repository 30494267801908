import React, { Component } from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import { FormattedMessage } from "gatsby-plugin-intl"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Hero from "../../components/hero"
import Demo from "../../components/demo"
import ClientCarousel from "../../components/clientCarousel"

import SegmentsPersonalization from "../../images/icons/segments-personalization.svg";
import PersonalizedCampaigns from "../../images/icons/personalized-campaigns.svg";
import RecoverForms from "../../images/icons/recover-forms.svg";
import ABTests from "../../images/icons/ab-tests.svg";
import Reporting from "../../images/icons/reporting.svg";

import DynamicContent from "../../images/icons/dynamic-content.svg";
import Automatic from "../../images/icons/automatic.svg";
import LeadScore from "../../images/icons/lead-score.svg";
import SMS from "../../images/icons/sms.svg";

class SuccessStories extends Component {
  reveal () {
    const ScrollReveal = require('scrollreveal').default

    ScrollReveal({
      viewFactor: 0.4,
      distance: '100px',
      duration: 1000
    });

    ScrollReveal().reveal('.sr-1', {
      delay: 0
    });

    ScrollReveal().reveal('.sr-2', {
      delay: 100
    });

    ScrollReveal().reveal('.sr-3', {
      delay: 200
    });

    ScrollReveal().reveal('.sr-r', {
      origin: 'right'
    });

    ScrollReveal().reveal('.sr-b', {
      origin: 'bottom'
    });

    ScrollReveal().reveal('.sr-q', {
      delay: 100,
      beforeReveal: function () {
        document.getElementsByClassName('sr-q')[0].classList.add('is-loaded');
      }
    });
  }

  playVideo () {
    if (!document.getElementsByClassName('play-video')[0].classList.contains('played')) {
      const iframe = '<iframe width="800" height="450" src="//youtube.com/embed/zkdXNfv2JZk?rel=0&autoplay=1" frameborder="0" allowfullscreen></iframe>';
      document.getElementsByClassName('play-video')[0].classList.add('played');
      document.getElementById('video-embed').innerHTML = iframe;
    }
  }

  componentDidMount () {
    if (typeof window !== 'undefined') {
      this.reveal();
    }
  }

  render () {
    return (
      <Layout>
        <SEO title="Ejemplos de éxito Automoción - ExpertSender" description="Ejemplos de éxito Automoción: ¡haga crecer su negocio mediante la automatización de marketing multicanal basado en datos y supere a su competencia!" />
        <Hero title="<span>Ejemplos de éxito </span>Automoción" subtitle="Compruebe cómo nuestros clientes aumentan su clientela y entablan con ella relaciones duraderas gracias al marketing multicanal." />

        <ClientCarousel />

        <div className="section-gr pad-t-3 pad-b-3 mar-t-3 mar-b-6">
          <div className="side side--both side--r-l side--gray" />
          <div className="container container--pad pad-t-6 pad-b-6">
            <div className="section-h">
              <h2 className="section-h__2 mar-t-3 mar-b-3">
                <FormattedMessage id="sell_more.h_ss" />
              </h2>
            </div>
   
            <div className="section-ss columns pad-t-1">
              <div className="column sr-hidden sr-1">
                <div className="card-ss card-ss--cover card-ss--full card-ss--shadow card-ss--pad play-video">
                  <div className="card-ss__img">
                    <Img fluid={this.props.data.mitsubishiCover.childImageSharp.fluid} className="card-ss__cover" />
                    <Img fixed={this.props.data.mitsubishiLogo.childImageSharp.fixed} className="card-ss__coverlogo" />
                    <div id="video-embed" />
                  </div>
                  <h3 className="card-ss__h card-ss__h--bigger">
                    <FormattedMessage id="automotive.ss_1" />
                  </h3>
                  <div className="columns mar-t-2">
                    <div className="card-ss__stat column is-narrow">
                      <span className="is-block has-text-weight-bold has-text-secondary">+767%</span>
                      CR
                    </div>
                    <div className="card-ss__stat column is-narrow">
                      <span className="is-block has-text-weight-bold has-text-secondary">-73%</span>
                      CAR
                    </div>
                    <div className="card-ss__stat column is-narrow">
                      <span className="is-block has-text-weight-bold has-text-secondary">+95%</span>
                      PVPAS
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container container--pad pad-t-3 mar-t-6 mar-b-6">
          <div className="columns is-variable is-8 is-vcentered">
            <div className="column sr-hidden sr-1">
              <div className="section-h section-h--full">
                <h2 className="section-h__2 mar-b-2">
                  <FormattedMessage id="automotive.h_1" />
                </h2>
                <p className="section-h__3">
                  <FormattedMessage id="automotive.t_1" />
                </p>
              </div>
            </div>
            <div className="column">
              <div className="columns mar-b-2 sr-hidden sr-1">
                <div className="column is-one-fifth">
                  <LeadScore className="section-i__ico" />
                </div>
                <div className="column">
                  <h3 className="section-h__3 has-text-weight-bold mar-b-1">
                    <FormattedMessage id="automotive.f_h_1" />
                  </h3>
                  <p>
                    <FormattedMessage id="automotive.f_t_1" />
                  </p>
                </div>
              </div>
              <div className="columns mar-b-2 sr-hidden sr-2">
                <div className="column is-one-fifth">
                  <RecoverForms className="section-i__ico" />
                </div>
                <div className="column">
                  <h3 className="section-h__3 has-text-weight-bold mar-b-1">
                    <FormattedMessage id="automotive.f_h_2" />
                  </h3>
                  <p>
                    <FormattedMessage id="automotive.f_t_2" />
                  </p>
                </div>
              </div>
              <div className="columns sr-hidden sr-3">
                <div className="column is-one-fifth">
                  <Automatic className="section-i__ico" />
                </div>
                <div className="column">
                  <h3 className="section-h__3 has-text-weight-bold mar-b-1">
                    <FormattedMessage id="automotive.f_h_3" />
                  </h3>
                  <p>
                    <FormattedMessage id="automotive.f_t_3" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container container--pad pad-t-3 mar-t-6 mar-b-6">
          <div className="columns is-variable is-6 mar-b-2">
            <div className="column sr-hidden sr-1">
              <SegmentsPersonalization className="section-i__ico" />
              <h3 className="section-h__3 has-text-weight-bold mar-t-2 mar-b-1">
                <FormattedMessage id="automotive.f_h_4" />
              </h3>
              <p>
                <FormattedMessage id="automotive.f_t_4" />
              </p>
            </div>
            <div className="column sr-hidden sr-2">
              <PersonalizedCampaigns className="section-i__ico" />
              <h3 className="section-h__3 has-text-weight-bold mar-t-2 mar-b-1">
                <FormattedMessage id="automotive.f_h_5" />
              </h3>
              <p>
                <FormattedMessage id="automotive.f_t_5" />
              </p>
            </div>
            <div className="column sr-hidden sr-3">
              <SMS className="section-i__ico" />
              <h3 className="section-h__3 has-text-weight-bold mar-t-2 mar-b-1">
                <FormattedMessage id="automotive.f_h_6" />
              </h3>
              <p>
                <FormattedMessage id="automotive.f_t_6" />
              </p>
            </div>
          </div>
        </div>

        <div className="container container--pad pad-b-1 mar-t-6 mar-b-6">
          <div className="columns is-variable is-6 mar-b-2">
            <div className="column sr-hidden sr-1">
              <ABTests className="section-i__ico" />
              <h3 className="section-h__3 has-text-weight-bold mar-t-2 mar-b-1">
                <FormattedMessage id="automotive.f_h_7" />
              </h3>
              <p>
                <FormattedMessage id="automotive.f_t_7" />
              </p>
            </div>
            <div className="column sr-hidden sr-2">
              <DynamicContent className="section-i__ico" />
              <h3 className="section-h__3 has-text-weight-bold mar-t-2 mar-b-1">
                <FormattedMessage id="automotive.f_h_8" />
              </h3>
              <p>
                <FormattedMessage id="automotive.f_t_8" />
              </p>
            </div>
            <div className="column sr-hidden sr-3">
              <Reporting className="section-i__ico" />
              <h3 className="section-h__3 has-text-weight-bold mar-t-2 mar-b-1">
                <FormattedMessage id="automotive.f_h_9" />
              </h3>
              <p>
                <FormattedMessage id="automotive.f_t_9" />
              </p>
            </div>
          </div>
        </div>

        <Demo props={this.props.data} />
      </Layout>
    )
  }
}

export default SuccessStories

export const pageQuery = graphql`
  query {
    mitsubishiCover: file(relativePath: { eq: "success-stories/mitsubishi-cover-full.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 821, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    mitsubishiLogo: file(relativePath: { eq: "success-stories/mitsubishi-logo.png" }) {
      childImageSharp {
        fixed(height: 80, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    esDashboard: file(relativePath: { eq: "expertsender-dashboard.png" }) {
      childImageSharp {
        fluid(maxWidth: 1260, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
